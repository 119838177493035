import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { IRootState } from 'src/store';
import {
  setIsAffiliatePayoutDialogOpen,
  setIsPayoutSubscriptionExpiredDialogOpen,
} from 'src/store/dashboard/slices/accountSlice';
import Iconify from 'src/components/iconify';

export default function PayoutSubscriptionExpiredDialog() {
  const dispatch = useDispatch();
  const { isPayoutSubscriptionExpiredDialogOpen } = useSelector(
    (state: IRootState) => state.account
  );

  const handleClose = () => {
    dispatch(setIsPayoutSubscriptionExpiredDialogOpen(false));
  };

  const handleCheckAffiliateTerms = () => {
    window.open(`https://docs.primelister.com/affiliate-program-guide`, '_blank');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isPayoutSubscriptionExpiredDialogOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle
        sx={{
          pb: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          justifyContent: 'center',
        }}
      >
        <Iconify
          sx={{
            width: '80px',
            height: '80px',
            color: 'warning.main',
          }}
          icon="eva:alert-circle-fill"
        />
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center',
            w: '100%',
          }}
        >
          Affiliate Program Eligibility Alert
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
          Please ensure you are an active subscriber on one of our paid plans to access and withdraw
          your funds.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          onClick={handleCheckAffiliateTerms}
          sx={{
            textWrap: 'nowrap',
          }}
        >
          Check Affiliate Terms
        </Button>
        <Button variant="outlined" onClick={handleClose} color="inherit">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
