import {
  Box,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/slices/automationsSlice';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Iconify from 'src/components/iconify';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import { Automation } from '@beta.limited/primelister';
import CategoryRichTreeView from 'src/pages/automations/MyListingsPage/components/shared/CategoryRichTreeView';

interface SelectedCategory {
  department: string;
  category?: string;
  subcategory?: string;
}

const PartyShareOptions = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:400px)');

  const {
    selectedAutomationOption,
    automationsSettings,
    isAutomationOptionDrawerOpen,
    activeClosetAutomationPlatform,
  } = useSelector((state: IRootState) => state.automations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.myCloset
  );

  const [isOptionsExpanded, setIsOptionsExpanded] = useState(true);

  const [shareContinuouslyEnabled, setShareContinuouslyEnabled] = useState(true);
  const [shareSelectedCategoriesEnabled, setShareSelectedCategoriesEnabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<SelectedCategory[]>([]);

  useEffect(() => {
    const config = automationsSettings[Automation.AUTO_PARTY_SHARE]?.config;
    if (config) {
      setShareContinuouslyEnabled(config.shareContinuously ?? true);
      setShareSelectedCategoriesEnabled(config.filters?.category?.enabled ?? false);
      setSelectedCategories(
        (config.filters?.category?.selectedCategories ?? []).filter(
          (cat): cat is SelectedCategory => typeof cat.department === 'string'
        )
      );
    }
  }, [automationsSettings]);

  const handleOptionChange =
    (optionName: string) => (event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) => {
      if (hasActiveClosetConnection) {
        const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
        let dataForUpdating: IDataForUpdateAutomationSettings = {
          settings: {},
          toastMessage,
          displayName: selectedAutomationOption.displayName,
          activeClosetAutomationPlatform,
          activeClosetCredentialsId,
        };

        if (optionName === 'shareContinuously') {
          dataForUpdating.settings = {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                shareContinuously: newValue,
              },
            },
          };
          setShareContinuouslyEnabled(newValue);
        } else if (optionName === 'shareSelectedCategories') {
          dataForUpdating.settings = {
            [Automation.AUTO_PARTY_SHARE]: {
              config: {
                filters: {
                  category: {
                    enabled: newValue,
                    selectedCategories: newValue ? selectedCategories : [],
                  },
                },
              },
            },
          };
          setShareSelectedCategoriesEnabled(newValue);
        }

        dispatch(updateAutomationSettingsRequest(dataForUpdating));
      } else {
        dispatch(setIsConnectionErrorDialogOpen(true));
      }
    };

  const handleCategoryChange = (newSelectedCategories: SelectedCategory[]) => {
    setSelectedCategories(newSelectedCategories);
    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_PARTY_SHARE]: {
          config: {
            filters: {
              category: {
                enabled: true,
                selectedCategories: newSelectedCategories,
              },
            },
          },
        },
      },
      toastMessage: `${selectedAutomationOption.displayName} automation configurations successfully updated`,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };
    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  const renderOptionSwitch = (
    optionName: string,
    label: string,
    tooltip: string,
    checked: boolean
  ) => (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        checked={checked}
        control={
          <Switch name={optionName} value={checked} onChange={handleOptionChange(optionName)} />
        }
        label={
          <Stack direction="row" alignItems="center" sx={{
            maxWidth: '280px'
          }}>
            <Box
            >
              {label}
            </Box>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              arrow
              title={tooltip}
              placement="top"
              sx={{ textAlign: 'center !important' }}
            >
              <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 1 }} />
            </Tooltip>
          </Stack>
        }
      />
    </Stack>
  );

  return (
    <Stack spacing={2} px={matches ? 0 : 1.5}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
          Options
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title="ℹ️ Customize your preferences for the Party Share automation."
            placement="top"
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Typography>

        <IconButton
          size="small"
          onClick={() => setIsOptionsExpanded(!isOptionsExpanded)}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify icon={isOptionsExpanded ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />
        </IconButton>
      </Stack>

      {isOptionsExpanded && (
        <>
          {renderOptionSwitch(
            'shareContinuously',
            'Share items continuously in loops',
            'ℹ️ Enable this option to continuously share available items in loops during the party. After all items are shared once, looping begins, and sharing continues until the party ends or your share limit is reached.',
            shareContinuouslyEnabled
          )}
          {renderOptionSwitch(
            'shareSelectedCategories',
            'Share listings from selected categories during the open-theme evening party',
            'ℹ️ Enable this option to share listings from your selected categories during the open-themed evening party. This ensures that only items from your specified categories are shared throughout the party, and other listings will be skipped.',
            shareSelectedCategoriesEnabled
          )}
          {shareSelectedCategoriesEnabled && (
            <CategoryRichTreeView
              initialSelectedCategories={selectedCategories}
              ruleIndex={0}
              onSelectedCategoriesChange={handleCategoryChange}
              updateAutomationSettings={false}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default PartyShareOptions;
