import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView, TreeItem } from '@mui/lab';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import { Automation } from '@beta.limited/primelister';
import { updateAutomationSettingsRequest } from 'src/store/automations/slices/automationsSlice';
import _ from 'lodash';
import { RenderTree } from './types/CategoryTreeView';

interface CategoryRichTreeViewProps {
  initialSelectedCategories: {
    department?: string;
    category?: string;
    subcategory?: string;
  }[];
  onSelectedCategoriesChange?: (
    selectedCategories: {
      department: string;
      category?: string;
      subcategory?: string;
    }[]
  ) => void;
  ruleIndex: number;
  updateAutomationSettings?: boolean;
}

interface ParentMapType {
  [key: string]: {
    children: string[];
    parentPath: string[];
    level: 'department' | 'category' | 'subcategory';
  };
}

interface SelectedItem {
  uniqueId: string;
  department: string;
  category?: string;
  subcategory?: string;
}

interface NodeChildStatus {
  checked: boolean;
  indeterminate: boolean;
}

export default function CategoryRichTreeView({
  initialSelectedCategories,
  ruleIndex,
  onSelectedCategoriesChange,
  updateAutomationSettings = true,
}: CategoryRichTreeViewProps) {
  const dispatch = useDispatch();
  const { filterCategories: data, rawFilterCategories } = useSelector(
    (state: IRootState) => state.automations
  );
  const { selectedAutomationOption, activeClosetAutomationPlatform, automationsSettings } =
    useSelector((state: IRootState) => state.automations);

  const { activeClosetCredentialsId } = useSelector((state: IRootState) => state.myCloset);

  const [selected, setSelected] = React.useState<SelectedItem[]>(() => {
    if (initialSelectedCategories.length === 0) return [];

    return initialSelectedCategories.flatMap((cat) => {
      const items: SelectedItem[] = [];
      if (cat.department) {
        items.push({
          uniqueId: `departments-${cat.department}`,
          department: cat.department,
        });

        if (cat.category) {
          items.push({
            uniqueId: `departments-${cat.department}-${cat.category}`,
            department: cat.department,
            category: cat.category,
          });

          if (cat.subcategory) {
            items.push({
              uniqueId: `departments-${cat.department}-${cat.category}-${cat.subcategory}`,
              department: cat.department,
              category: cat.category,
              subcategory: cat.subcategory,
            });
          }
        }
      }
      return items;
    });
  });

  const parentMap = React.useMemo<ParentMapType>(() => goThroughAllNodes(data) || {}, [data]);

  const isSelected = React.useCallback(
    (uniqueId: string): boolean => {
      if (uniqueId === 'departments') {
        const allDescendants = getAllDescendants(data, ['departments']);
        return (
          allDescendants.length > 0 &&
          allDescendants.every((desc) => selected.some((item) => item.uniqueId === desc.uniqueId))
        );
      }

      // Check if the node is directly selected
      if (selected.some((item) => item.uniqueId === uniqueId)) {
        return true;
      }

      // Check if all children are selected
      const nodeInfo = parentMap[uniqueId.split('-').pop() || ''];
      if (nodeInfo && nodeInfo.children.length > 0) {
        return nodeInfo.children.every((childId) => isSelected(`${uniqueId}-${childId}`));
      }

      return false;
    },
    [selected, data, parentMap]
  );

  function goThroughAllNodes(
    nodes: RenderTree,
    map: ParentMapType = {},
    parentPath: string[] = []
  ): ParentMapType {
    if (!nodes.children) {
      return map;
    }

    const level = getLevel(parentPath.length);

    map[nodes.id] = {
      children: getAllChild(nodes).splice(1),
      parentPath: [...parentPath],
      level,
    };

    for (let childNode of nodes.children) {
      goThroughAllNodes(childNode, map, [...parentPath, nodes.id]);
    }

    return map;
  }

  function getLevel(parentCount: number): 'department' | 'category' | 'subcategory' {
    switch (parentCount) {
      case 0:
        return 'department';
      case 1:
        return 'category';
      default:
        return 'subcategory';
    }
  }

  function getAllChild(childNode: RenderTree | null, collectedNodes: string[] = []): string[] {
    if (childNode === null) return collectedNodes;

    collectedNodes.push(childNode.id);

    if (Array.isArray(childNode.children)) {
      for (const node of childNode.children) {
        getAllChild(node, collectedNodes);
      }
    }

    return collectedNodes;
  }

  const updateSelectedCategories = (newSelected: SelectedItem[]) => {
    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
    const selectedCategories = newSelected
      .map((item) => {
        const parts = item.uniqueId.split('-');
        const startIndex = parts.indexOf('departments') + 1;
        const relevantParts = parts.slice(startIndex);

        if (relevantParts.length < 1) return null;

        return {
          department: relevantParts[0],
          category: relevantParts[1] || '',
          subcategory: relevantParts[2] || '',
        };
      })
      .filter((item): item is NonNullable<typeof item> => item !== null)
      .map((item) => {
        const departmentNode = rawFilterCategories.find((node) => node.id === item.department);
        if (!departmentNode) return null;

        const categoryNode = departmentNode.children?.find((node) => node.id === item.category);
        const subcategoryNode = categoryNode?.children?.find(
          (node) => node.id === item.subcategory
        );

        return {
          department: departmentNode.id,
          category: categoryNode?.id || '',
          subcategory: subcategoryNode?.id || '',
        };
      })
      .filter((item): item is NonNullable<typeof item> => item !== null);

    if (onSelectedCategoriesChange) {
      onSelectedCategoriesChange(selectedCategories);
    }
    if (updateAutomationSettings) {
      const currentAutomation = automationsSettings[Automation.AUTO_SEND_OFFER_TO_LIKERS];

      const dataForUpdating = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            ...currentAutomation,
            config: {
              ...currentAutomation.config,
              rules: currentAutomation.config.rules.map((rule, index) =>
                index === ruleIndex
                  ? {
                      ...rule,
                      filters: {
                        ...rule.filters,
                        category: {
                          enabled: selectedCategories.length > 0,
                          selectedCategories,
                        },
                      },
                    }
                  : rule
              ),
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
        ruleIndex,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    }
  };

  const handleAllDepartmentsSelection = (checked: boolean) => {
    if (checked) {
      const allNodes = getAllDescendants(data, ['departments']);
      setSelected(allNodes);
      updateSelectedCategories(allNodes);
    } else {
      setSelected([]);
      updateSelectedCategories([]);
    }
  };

  function getAllDescendants(node: RenderTree, currentPath: string[]): SelectedItem[] {
    const result: SelectedItem[] = [];

    if (node.id !== 'departments') {
      result.push({
        uniqueId: currentPath.join('-'),
        department: currentPath[0],
        category: currentPath[1] || undefined,
        subcategory: currentPath[2] || undefined,
      });
    }

    if (node.children) {
      for (const child of node.children) {
        result.push(...getAllDescendants(child, [...currentPath, child.id]));
      }
    }

    return result;
  }

  const updateParentSelection = (
    currentPath: string[],
    newSelected: SelectedItem[]
  ): SelectedItem[] => {
    const checkChildrenAndUpdateParent = (
      parentPath: string[],
      parentInfo: ParentMapType[string]
    ) => {
      const parentId = parentPath.join('-');
      const allChildrenSelected = parentInfo.children.every((childId) =>
        newSelected.some((item) => item.uniqueId === `${parentId}-${childId}`)
      );

      if (allChildrenSelected) {
        const parentItem: SelectedItem = {
          uniqueId: parentId,
          department: parentPath[1] || '',
          category: parentPath[2] || undefined,
          subcategory: parentPath[3] || undefined,
        };
        newSelected = _.unionBy(newSelected, [parentItem], 'uniqueId');
      }
    };

    for (let i = currentPath.length - 1; i >= 0; i--) {
      const parentPath = currentPath.slice(0, i + 1);
      const parentInfo = parentMap[parentPath[i]];

      if (parentInfo) {
        checkChildrenAndUpdateParent(parentPath, parentInfo);
      }
    }
    return newSelected;
  };
  const getOnChange = React.useCallback(
    (checked: boolean, nodes: RenderTree, currentPath: string[]) => {
      if (nodes.id === 'departments') {
        handleAllDepartmentsSelection(checked);
        return;
      }

      const uniqueId = currentPath.join('-');

      let newSelected: SelectedItem[];
      if (checked) {
        const itemsToAdd = getAllDescendants(nodes, currentPath);
        newSelected = _.unionBy(selected, itemsToAdd, 'uniqueId');
        newSelected = updateParentSelection(currentPath, newSelected);
      } else {
        newSelected = selected.filter((item) => !item.uniqueId.startsWith(uniqueId));
      }

      setSelected(newSelected);
      updateSelectedCategories(newSelected);
    },
    [selected, setSelected, updateSelectedCategories, parentMap]
  );

  const isIndeterminate = React.useCallback(
    (nodeId: string, currentPath: string[]): boolean => {
      const uniqueId = [...currentPath, nodeId].join('-');
      const checked = isSelected(uniqueId);
      const nodeInfo = parentMap[nodeId];
      const isAllDepartments = nodeId === 'departments';

      // If the node is fully checked, it's not indeterminate
      if (checked) return false;

      if (isAllDepartments) {
        const allDescendants = getAllDescendants(data, ['departments']);
        const selectedCount = allDescendants.filter((desc) =>
          selected.some((item) => item.uniqueId === desc.uniqueId)
        ).length;
        return selectedCount > 0 && selectedCount < allDescendants.length;
      }

      if (!nodeInfo?.children.length) return false;

      const childrenStatus: NodeChildStatus[] = nodeInfo.children.map((childNodeId: string) => {
        const childUniqueId = [...currentPath, nodeId, childNodeId].join('-');
        const childChecked = isSelected(childUniqueId);
        const childIndeterminate = isIndeterminate(childNodeId, [...currentPath, nodeId]);
        return { checked: childChecked, indeterminate: childIndeterminate };
      });

      const allChildrenSelected = childrenStatus.every((status) => status.checked);
      const someChildrenSelected = childrenStatus.some(
        (status: NodeChildStatus) => status.checked || status.indeterminate
      );

      return someChildrenSelected && !allChildrenSelected;
    },
    [isSelected, parentMap, data, selected]
  );
  const renderTree = (nodes: RenderTree, currentPath: string[] = []) => {
    const uniqueId = [...currentPath, nodes.id].join('-').replace(/ /g, '_');
    const checked = isSelected(uniqueId);
    const indeterminate = isIndeterminate(nodes.id, currentPath);
    const isAllDepartments = nodes.id === 'departments';

    return (
      <TreeItem
        key={uniqueId}
        nodeId={uniqueId}
        label={
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={checked}
                indeterminate={indeterminate}
                onChange={(event) =>
                  isAllDepartments
                    ? handleAllDepartmentsSelection(event.target.checked)
                    : getOnChange(event.target.checked, nodes, [...currentPath, nodes.id])
                }
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={
              <Typography
                sx={{
                  color: '#212B36',
                  fontSize: '14px',
                  lineHeight: '22px',
                }}
              >
                {nodes.name}
              </Typography>
            }
            key={uniqueId}
          />
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node, [...currentPath, nodes.id]))
          : null}
      </TreeItem>
    );
  };

  const [expanded, setExpanded] = useState<string[]>(['departments']);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      expanded={expanded}
      defaultParentIcon={null}
      defaultEndIcon={null}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
      sx={{
        '& .MuiTreeItem-content': {
          flexDirection: 'row-reverse',
        },
      }}
    >
      {renderTree(data)}
    </TreeView>
  );
}
