import { DataGridPro, GridRowParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { IRootState } from 'src/store';
import {
  setSelectedImportCheckboxes,
  setJustCompletedList,
  setIsLoadMoreLoading,
  IImportListingItem,
} from 'src/store/dashboard/slices/importTableSlice';
import { columns } from './Colums';
import ImportSearchBar from '../others/ImportSearchBar';
import { lighten, useTheme } from '@mui/material/styles';
import EmptyContent from 'src/components/empty-content/EmptyContent';
import {
  IAPIMarketplaces,
  officialApiPlatforms,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { useImportDataForInventory } from 'src/hooks/useImportDataForInventory';
import HideImportedLoading from '../others/HideImportedLoading';
import { isEbay } from './helpers';
import { uniqBy } from 'lodash';
import { SupabaseEventType } from 'src/utils/SupabaseBroadcast';
import { Marketplace } from '@beta.limited/primelister';

export default function ImportInventoryDataGrid() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const {
    selectedImportCheckboxes,
    hideImportedItems,
    listingsForImport,
    importLoading,
    importedListingsList,
    justCompletedList,
    inProgressList,
    hasMoreImportableListings,
    isLoadMoreLoading,
    hasActiveSearch,
    fetchedListings,
    importNextPageID,
    importPlatform,
  } = useSelector((state: IRootState) => state.importTable);

  const isIncludedInLists = (id: string) =>
    importedListingsList.includes(id) || justCompletedList.includes(id);
  const { handleLoadMore } = useImportDataForInventory(
    officialApiPlatforms as IAPIMarketplaces[],
    isIncludedInLists
  );

  const handleSelectedCheckboxes = (checkboxes: GridSelectionModel) => {
    dispatch(setSelectedImportCheckboxes(checkboxes as string[]));
    const selectableListings = filteredListings().filter(
      (item) => !isIdIncludedOrInProgress(item.id)
    );
    setIsAllSelected(checkboxes.length === selectableListings.length);
  };

  const isIdIncludedOrInProgress = (id: string) =>
    isIncludedInLists(id) || inProgressList.includes(id);

  useEffect(() => {
    const checkIsJustImported = (payload: any) => {
      dispatch(setJustCompletedList([...justCompletedList, payload.detail.payload.id]));
    };

    window.supabase.events.addEventListener(SupabaseEventType.TASK_COMPLETE, checkIsJustImported);
  }, [justCompletedList]);

  useEffect(() => {
    if (isAllSelected) {
      const selectableRows = filteredListings()
        .filter((item) => !isIdIncludedOrInProgress(item.id))
        .map((item) => item.id);
      dispatch(setSelectedImportCheckboxes(selectableRows));
    }
  }, [listingsForImport]);

  const rowClassNameGetter = (params: GridRowParams) =>
    `import-status-${isIdIncludedOrInProgress(params.row.imported.id)}`;

  const isRowSelectable = (params: GridRowParams) =>
    !isIdIncludedOrInProgress(params.row.imported.id);

  const filteredListings = () => {
    const unImportedListings = listingsForImport.filter((item) => !isIncludedInLists(item.id));
    const listingsToRender = hideImportedItems ? unImportedListings : listingsForImport;
    !listingsToRender.length && hasActiveSearch && dispatch(setIsLoadMoreLoading(false));
    // Remove duplicates
    const uniqueListingsToRender = uniqBy(listingsToRender, 'id');

    return uniqueListingsToRender;
  };

  const noRowsLabel =
    'All Listings Imported: All your available listings have been already imported successfully.';

  const handleOnRowsScrollEnd = async () => {
    if (hasMoreImportableListings && !isLoadMoreLoading) {
      await handleLoadMore(importNextPageID);
    }
  };

  const showHideImportedInitialLoading =
    hideImportedItems && isLoadMoreLoading && !fetchedListings.length;

  // If there are less then 10 items than make threshold 0 the load more works better
  const scrollEndThresholdValue = filteredListings().length < 10 ? 0 : 80;

  const isHidingToolbar = [Marketplace.DEPOP, Marketplace.GRAILED].includes(
    importPlatform as Marketplace
  );

  return (
    <DataGridPro
      density={'comfortable'}
      loading={importLoading || isLoadMoreLoading}
      components={{
        Footer: () => <></>,
        ...(!isHidingToolbar && { Toolbar: ImportSearchBar }),
        ...(showHideImportedInitialLoading && {
          LoadingOverlay: HideImportedLoading,
        }),
        NoRowsOverlay: () => <EmptyContent title={noRowsLabel} />,
        NoResultsOverlay: () => <EmptyContent title="No results found." />,
      }}
      scrollEndThreshold={scrollEndThresholdValue}
      columns={columns}
      rows={
        !importLoading
          ? filteredListings().map((item) => ({
              id: item.id,
              image: item.img,
              title: item.name,
              price: item.price,
              imported: {
                id: item.id,
              },
            }))
          : []
      }
      rowCount={filteredListings().length}
      getRowClassName={rowClassNameGetter}
      isRowSelectable={isRowSelectable}
      selectionModel={selectedImportCheckboxes}
      checkboxSelection={true}
      disableSelectionOnClick={true}
      disableColumnResize={true}
      disableColumnSelector={true}
      disableColumnPinning={true}
      disableColumnReorder={true}
      onSelectionModelChange={(selectionModel: GridSelectionModel) => {
        handleSelectedCheckboxes(selectionModel);
      }}
      onRowsScrollEnd={handleOnRowsScrollEnd}
      sx={{
        '& .MuiDataGrid-cell': {
          whiteSpace: 'normal !important',
          wordBreak: 'break-word !important',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { p: '10px' },
        '& .import-status-true': {
          color: lighten(theme.palette.grey[600], 0.3),
        },
      }}
    />
  );
}
