import { Stack, Typography, Box, Divider } from '@mui/material';
import PartyShareOptions from './PartyShareOptions';
import SharingLimits from './SharingLimits';

const PartyShareSettings = () => (
  <Stack spacing={2.5} divider={<Divider sx={{ borderStyle: 'dashed' }} />}>
    <SharingLimits />
    <PartyShareOptions />
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        borderRadius: 1,
        padding: '10px 14px',
        border: '1px dashed #ccc',
      }}
    >
      <Typography sx={{ fontSize: '14px', marginBottom: 2 }}>
        ℹ️
      </Typography>
      <Typography
        sx={{
          minWidth: '332px',
        }}
        variant="body2"
        color="text.primary"
        align="center"
      >
        PrimeLister maximizes the sharing of your available items in Poshmark Parties, fine-tuning
        speed, frequency, and duration within your limits.
      </Typography>
    </Box>
  </Stack>
);

export default PartyShareSettings;
